<template>
  <v-data-table
    :headers="headers"
    :items="inSituLayer"
    sort-by="name"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat class="mb-5">
        <v-toolbar-title>{{
          $t("correlationTool.inSituStation")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title
              >{{ $t("safeDelete") }} {{ editedItem.name }}?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="red" text @click="deleteItemConfirm">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.properties="{ item }">
      {{ createPropertyString(item.properties) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon :disabled="item.owner == false" small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import analyseMixin from "@/core/mixins/analyse.mixin";

export default {
  name: "InSituData",
  mixins: [analyseMixin],
  data: () => ({
    search: "",
    dialogDelete: false,
    editedItem: {
      id: 0,
      name: "",
      properties: []
    },
    defaultItem: {
      id: 0,
      name: "",
      properties: []
    }
  }),

  computed: {
    ...mapGetters("management", ["activeRegion"]),
    ...mapGetters("vector", ["inSituLayer"]),
    headers() {
      return [
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Properties", value: "properties" },
        { text: "Actions", value: "actions", sortable: false }
      ];
    },
    deleteUrl() {
      return `/vector/${this.$appConfig.keycloakClient}/region/${this.activeRegion.id}/layers/${this.editedItem.id}`;
    }
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  methods: {
    ...mapActions("vector", ["fetchVectorLayer"]),
    deleteItem(item) {
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await axios.delete(this.deleteUrl);
      await this.fetchVectorLayer(this.activeRegion.id);
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
      });
    },

    createPropertyString(properties) {
      return properties.map(item => item.name).toString();
    }
  }
};
</script>

<style scoped></style>
